import React from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { withLayout } from './Layout'
import { Container, HeaderLarge, Section } from './base'

interface MdxLayoutProps {
  children: React.ReactNode
  metadata: {
    title: string
  }
}

const MdxLayout = (props: MdxLayoutProps): React.ReactElement => {
  const {
    children,
    metadata: { title },
  } = props
  return (
    <Container>
      {/* <Header Icon={FaInfoCircle} title={title} /> */}
      {/* <HeaderLarge title={title} /> */}
      <Section flexDirection="column">{children}</Section>
    </Container>
  )
}

export default withLayout(MdxLayout)
