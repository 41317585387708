import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { SectionBox, SubtitleMD, TitleMD } from '../base';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SectionBox bg="primary.main" mdxType="SectionBox">
      <TitleMD mdxType="TitleMD">Merchant Terms and Conditions</TitleMD>
    </SectionBox>
    <SectionBox bg="primary.text" mdxType="SectionBox">
      <SubtitleMD mdxType="SubtitleMD">Choice Limited</SubtitleMD>
      <SubtitleMD mdxType="SubtitleMD">Merchant Terms and Conditions</SubtitleMD>
      <h3>{`These terms and conditions govern your use of the Choice Platform. Please read them before you register as a Choice Registered Merchant.`}</h3>
      <h2>{`1. Terms and Conditions for use of Choice Platform`}</h2>
      <p>{`1.1. Acceptance of Terms and Conditions: These terms and conditions are the terms on which access to the Choice Platform is provided and you use and receive the benefit of the mobile payment processing service provided by Choice. By registering to become a Choice Registered User with Choice, you accept these terms and conditions. If you do not accept these terms and conditions, you must not use the Choice Platform.`}</p>
      <p>{`1.2. Variation to Terms and Conditions: These terms and conditions may be varied by Choice from time to time by posting a notice of change on Choice’s website. Notification will also be made to the email address recorded in your Choice Registered User account. The variation to the terms and conditions will take effect 10 Business Days after the notice was posted on Choice’s website. By continuing to use the Choice Platform after the 10 Business Day notice period, you agree to be bound by the varied terms and conditions as published.`}</p>
      <p>{`1.3 Assignment:`}</p>
      <p>{`1.3.1. You may not assign your rights and obligations under these terms and conditions without the prior written
consent of Choice. For the avoidance of doubt, a change in control of an entity will be deemed to be an assignment
for the purposes of these terms and conditions.`}</p>
      <p>{`1.3.2. Choice can assign its rights and obligations under these terms and conditions to any person at any time.
Notification of such assignment will be made by posting a notice on Choice’s website. The assignment will take effect
10 Business Days after posting on Choice’s website. By continuing to use the Choice Platform after
the 10 Business Day notice period, you agree to be bound by the assignment.`}</p>
      <p>{`1.4. Becoming a Choice Registered Merchant: Becoming a Choice Registered Merchant is free. It forms a legally binding contract which is enforceable against you.`}</p>
      <p>{`1.5. Governing Law: These terms and conditions are governed by New Zealand law. The New Zealand courts shall have non-exclusive jurisdiction in relation to any proceedings regarding these terms and conditions.`}</p>
      <h2>{`2. Service provided by Choice`}</h2>
      <p>{`2.1. The Service: Choice provides a mobile payments processing facility for receiving payments to your business from customers, via a mobile application. Customers pay by scanning a QR code that is generated on a Choice merchant interface in store."`}</p>
      <p>{`2.1.1. On the customer side, the customer downloads the Choice mobile app onto his or her mobile phone
(and securely records his or her payment details). When going to pay for a product or service, the customer scans
a Choice QR code to pay at the counter, authorises the payment to the Merchant and also nominates the charity
that will receive the impact fee.`}</p>
      <p>{`2.1.2. The Choice Platform provides a payment processing service, which pays the Merchant the purchase
price of the goods or services, less a nominated transaction fee and also pays the equivalent impact fee
to the charity nominated by the Customer.`}</p>
      <p>{`This is explained in more detail throughout the agreement.`}</p>
      <p>{`2.2 Choice is not party to Customer / Merchant contract:`}</p>
      <p>{`2.2.1. Choice does not take any part in the contract for sale and purchase of goods or services between you and
Customers registered with Choice. The Choice Platform merely provides a means of communicating orders either
from or to you and facilitating payment for the goods or services purchased.`}</p>
      <p>{`2.2.2. Choice accepts no responsibility or liability for any issues which may arise between you and Customers.
Responsibility for any refund or default rests with you.`}</p>
      <p>{`2.2.3. If you have a dispute regarding goods or services purchased from you, you must resolve the dispute directly
with the Customer. Any reimbursement for goods and services must be arranged between you and the Customer
directly – it cannot be re-credited back to a Customer’s Choice wallet.`}</p>
      <p>{`2.3. Choice’s right to cancel the Choice Platform: Choice reserves the right to cancel the Choice Platform at any time.`}</p>
      <p>{`2.4 Choice Beta: Choice may invite You to trial the Choice Platform while still in development (whether described as beta, pilot, non-production, limited release, evaluation or otherwise) (Choice Beta), at no charge, for a limited period that we agree in writing. You may accept or decline in Your sole discretion. If You accept, You acknowledge and agree that:`}</p>
      <p>{`2.4.1. Choice Beta is for evaluation purposes (not production use), it is provided “as is where is”, it is not
considered the Choice Platform under this Agreement, and that Choice will have no liability for any harm
or damage arising out of or in connection with Your use of Choice Beta; and`}</p>
      <p>{`2.4.2. You will notify Choice of all problems and/or ideas for enhancements which come to your attention during
that period (which you agree to assign absolutely to Choice), and at the end of that period, You will provide
material, statistics and/or relevant information to Choice relating to the capability, and performance of Choice
Beta, to enable Choice to continue developing and enhancing the Choice Platform in readiness for general release.`}</p>
      <h2>{`3. GST`}</h2>
      <p>{`3.1. Time of supply: You agree that the time a Customer uses the Choice Platform to purchase goods or services from you will be treated as the time of supply of goods and services for the purposes of Goods and Services Tax Act 1985.`}</p>
      <h2>{`4. Merchant obligations`}</h2>
      <p>{`4.1. Your obligations: You will:`}</p>
      <p>{`4.1.1. Keep your Choice Platform login information, including your Registered Merchant name and password,
secret and secure.`}</p>
      <p>{`4.1.2. Take all reasonable steps to keep your systems secure and free from any unauthorised access or
computer virus.`}</p>
      <p>{`4.1.3. Provide, at the request of Choice, full and correct details of the goods and/or services provided to a
Customer together with copies of all documentation evidencing the trade with a Customer(e.g. booking information,
tax invoices, statements).`}</p>
      <p>{`4.1.4. Notify Customers if you are passing on to the Customer the cost of any fees incurred by you in relation to
your use of the Choice Platform (such as the withdrawal fee contained in clause 6.1).`}</p>
      <p>{`4.1.5. Deal promptly with all customer service issues which arise from any purchase of goods and/or services
by a Customer using Choice’s mobile payments processing service.`}</p>
      <p>{`4.1.6. Meet all of your obligations under all applicable consumer legislation.`}</p>
      <p>{`4.1.7. Immediately refund the full amount paid by the Customer for the goods and/or services, if a refund is due
to a Customer. Any refund for goods and services must be arranged between you and the Customer
directly – it cannot be re-credited back to a Customer’s ’s mobile wallet.`}</p>
      <p>{`4.1.8. Not do any act which, in Choice’s reasonable opinion, damages its reputation or brings Choice, its Platform
or the website into disrepute.`}</p>
      <p>{`4.1.9. Meet all of your obligations under all applicable consumer legislation.`}</p>
      <p>{`4.1.10. Not use the Choice Platform for any dishonest, fraudulent or otherwise illegal purpose.`}</p>
      <p>{`4.1.11. Immediately notify Choice of any suspicious, fraudulent or unauthorised transactions.`}</p>
      <h2>{`5. Choice’s rights and obligations`}</h2>
      <p>{`5.1. Payment procedure for Choice initiated payments to you: The following is the payment procedure where a payment request is initiated by you as the Choice Registered Merchant:`}</p>
      <p>{`5.1.1. You must register with Choice as a Choice Registered Merchant, completing an application form
and supplying all information required by Choice in order for Choice to onboard you (in other words,
complete the application and verification process).`}</p>
      <p>{`5.1.2. The Choice system accepts transactions on your behalf from Customers, in accordance with 2.1.1.`}</p>
      <p>{`5.1.3. Choice will provide a net total settlement (transactions minus transaction fee) the following business day
into your nominated bank account.`}</p>
      <p>{`5.2. Payment procedure for Customer initiated payments: The following is the payment procedure for where payment to you is initiated by a Customer:`}</p>
      <p>{`5.2.1. The Customer must download the Choice mobile application onto his or her mobile phone
(and securely record his or her payment details);`}</p>
      <p>{`5.2.2. Choice will notify the Customer when his or her details have been verified and he or she is accepted as a
Customer for the Choice Platform;`}</p>
      <p>{`5.2.3. The Customer can scan Choice QR codes at Choice Registered Merchants to pay them directly.`}</p>
      <p>{`5.3. Choice’s obligations: Choice will process transactions in accordance with clause `}{`[5.1 or 5.2]`}{`, provided that:`}</p>
      <p>{`5.3.1. There is sufficient credit in the Customer’s relevant account to pay for purchase from you.`}</p>
      <p>{`5.3.2. Choice has received all necessary documentation from the Customer (or you on the Customer’s behalf)
evidencing your trade with the Customer.`}</p>
      <p>{`5.3.3. The transaction verification process has been completed and Choice is satisfied (in its sole discretion)
that the trade is a genuine transaction.`}</p>
      <p>{`5.4. Suspending or cancelling transactions: Choice can in its sole discretion suspend or cancel a transaction through the Choice Platform if Choice has any reason to suspect the transaction involves fraud, money laundering or financing of terrorism. Choice will notify you by email if a transaction is suspended or cancelled.`}</p>
      <p>{`5.5. Preventing use of Choice Platform by Merchant: Choice reserves the right to suspend or terminate the membership of a Merchant or any other person from using the Choice Platform at its sole discretion.`}</p>
      <h2>{`6. Fees`}</h2>
      <p>{`6.1. Withdrawal Fee: will charge you a transaction fee for each Customer payment processed by Choice (as described in the payment procedures in clauses 5.1 and 5.2).`}</p>
      <h2>{`7. Personal information`}</h2>
      <p>{`7.1. Registering as a merchant: You must complete the Choice Registered Merchant application form to Choice’s satisfaction. Choice may decide in its sole discretion whether or not to accept your application.`}</p>
      <p>{`7.2.Accurate information: You warrant that you have provided complete, accurate and current information when registering as a Merchant with Choice, and that you will immediately notify Choice of any changes to the details provided in your registration application.`}</p>
      <p>{`7.3. Requests for additional information: Choice may make enquiries about any personal information that you provide to Choice in order to check the accuracy of that information. You agree to provide all reasonable information (including any documentation) requested by Choice to assist Choice to meet its obligations under the Anti-Money Laundering and Countering Financing of Terrorism Act 2009 and any other relevant laws.`}</p>
      <p>{`7.4. Updating your information:`}</p>
      <p>{`7.4.1. You must maintain and update your information held by us to ensure it is kept current at all times. We may
call or email you to verify these details.`}</p>
      <p>{`7.4.2. We provide you with ways in which you can change, update or add to your information, however the
responsibility for updating the information rests solely with you.`}</p>
      <p>{`7.5. Privacy: In accordance with the provisions of the Privacy Act 1993, Choice may collect personal information about you (including your Merchant registration details, Customer transactions with you and your use of the Choice Platform. Please see the Privacy Policy attached to these Terms and Conditions.`}</p>
      <p>{`7.6. Security of your information: You are responsible for keeping your login information, including your Merchant name and password, secret and secure. In particular, you agree to not permit any other person to use your user login information and not to disclose, or provide to any other person your Merchant name, password or any other information in connection with your Merchant account that may allow them to gain access to your account.`}</p>
      <h2>{`8. Use of Merchant information and intellectual property`}</h2>
      <p>{`8.1. Right to Display: Choice may use your business name, trademark, logo or website domain on the Choice website or in promotional materials associated with the Choice Platform and its services.`}</p>
      <p>{`8.2. Merchant warranty: You warrant to Choice that any images and/or text supplied by you for use on the Choice website or promotional materials are your property and by using such images and/or text neither you nor Choice will be in breach of any third party’s intellectual property rights. You indemnify Choice against any loss suffered or claim which is brought against Choice by any third party in connection with the use of any intellectual property supplied to Choice by you.`}</p>
      <p>{`8.3. Intellectual property is accurate and current: You agree that any images, text and/or information supplied to Choice for use on the Choice website or in promotional materials is correct at the time of supply and, in the event of any change in the images, text and/or information you will provide to Choice the correct images, text and/or information as soon as practicable.`}</p>
      <p>{`8.4. Changes to Choice’s website and materials: Choice may change or remove the text, images and/or information displayed on the website or in promotional materials in regard to you at any time and without prior notice to you.`}</p>
      <h2>{`9. Use of Choice intellectual property`}</h2>
      <p>{`9.1. Right to Display: You may use Choice’s business name, trademark, logo or website domain on your website for the purposes of promoting the Choice Platform and its services and confirming you are a Choice Registered Merchant.`}</p>
      <p>{`9.2. Merchant obligations: You will:`}</p>
      <p>{`9.2.1. Only use Choice’s intellectual property in a form and manner approved by Choice.`}</p>
      <p>{`9.2.2. Not use or refer to Choice’s intellectual property in a way that is or may be confusing, misleading or deceptive.`}</p>
      <p>{`9.2.3. Not use or refer to Choice’s intellectual property in a way that is detrimental to Choice’s reputation or goodwill.`}</p>
      <p>{`9.2.4 Not, without Choice’s prior written permission, in any form or by any means:`}</p>
      <p>{`a. Adapt, reproduce, copy, store, distribute, print, display, perform, publish or create derivative works from any
part of the Choice Platform or website; or`}</p>
      <p>{`b. Commercialise, copy, or on-sell any information, or items obtained from any part of the Choice Platform
or website.`}</p>
      <p>{`9.2.5 Comply with Choice’s reasonable instructions as to the use of the intellectual property.`}</p>
      <p>{`9.2.6 Immediately cease using Choice’s intellectual property if Choice requests you to do so or if your Merchant account is suspended or terminated by Choice for any reason.`}</p>
      <p>{`9.3. Choice warrants that `}{`[to the best of its knowledge and belief,]`}{` the intellectual property supplied by it for use on your website is Choice’s property and by using such images and/or text neither you nor Choice will be in breach of any third party’s intellectual property rights.`}</p>
      <p>{`9.4. Ownership of intellectual property:`}</p>
      <p>{`9.4.1. Ownership of Choice’s intellectual property remains with Choice at all times. These terms and conditions
do not confer on you any right, title or interest in the intellectual property, other than the right to use the intellectual
property in accordance with these terms and conditions.`}</p>
      <p>{`9.4.2. Choice or its assigns, own all proprietary and intellectual property rights of the Choice Platform and in
the Choice website (including text, graphics, logos, icons) and the software and other material underlying and
forming part of the services or the website.`}</p>
      <h2>{`10. No liability`}</h2>
      <p>{`10.1. Fraudulent activity: While Choice has used its best endeavours to ensure that appropriate systems are in place to reduce the risk of fraudulent activity, Choice is not responsible for protecting Choice Registered Merchants from forms of wrongful or fraudulent activity.`}</p>
      <p>{`10.2. Unauthorised access: Provided that Choice has taken reasonable steps to protect its systems, Choice does not accept any liability for any damage, loss, cost expense or harm arising directly or indirectly as a result of any computer virus or unauthorised access of our system.`}</p>
      <p>{`10.3. Inability to use Choice Platform: Choice accepts no responsibility for any inability to use the service that arises from an issue between you and your network or communication provider.`}</p>
      <p>{`10.4. Breach by Merchant: Without limiting any other rights and remedies available to Choice, Choice may limit your use of the Choice Platform, terminate your Choice Registered Merchant account, or refuse to provide Choice services to you if in Choice’s opinion you breach these terms and conditions.`}</p>
      <p>{`10.5. Liability: Notwithstanding any other terms of this agreement you agree that, to the maximum extent permitted by law, any and all liability and responsibility of Choice to you or any other person under or in connection with these terms and conditions, the Choice Platform, Choice’s website, any Choice Customer’s act or omission, or your use of or inability to use the Choice Platform, is excluded regardless of whether such liability arises in contract, tort (including negligence), equity, breach of statutory duty or otherwise, Choice’s liability and responsibility is excluded in respect of any and all loss or damage, whether direct or indirect, including, without limitation, loss of profits, loss of data, loss of business or anticipated savings, general and special damages, and consequential and incidental loss.`}</p>
      <p>{`10.6. Indemnity: By using this service, you agree to indemnify Choice from and against all actions, claims, costs (including legal costs and expenses on a solicitor and client basis), Choice to any person arising out of or in connection with your failure to comply with these terms and conditions or failure by you to complete a transaction with a Customer or out of or in connection with your use of the Choice Platform.`}</p>
      <p>{`10.7. Force Majeure: Choice has no liability for any lack of performance, or for any failure of Choice to comply with these terms and conditions where the same arises from any cause reasonably beyond Choice’s control.`}</p>
      <p>{`10.8. Consumer Guarantees Act: You acknowledge that you are using Choice’s service for business purposes and accordingly the provisions of the Consumer Guarantees Act 1993 do not apply.`}</p>
      <p>{`10.9. No Waiver: If Choice does not exercise or enforce any right available to us under these terms and conditions, it does not constitute a waiver of those rights.`}</p>
      <h2>{`11. Termination or suspension`}</h2>
      <p>{`11.1. By Choice: Choice may in its sole discretion suspend or terminate your Choice Registered Merchant account and/or your use of the Choice Platform or Choice website immediately upon notice.`}</p>
      <p>{`11.2. By Merchant: You may at any time request that Choice suspend or terminate your Choice Registered Merchant account and/or your use of the Choice Platform or Choice website.`}</p>
      <p>{`11.3. Obligations on termination or suspension: Upon termination or suspension:`}</p>
      <p>{`11.3.1. You will not hold out or represent that you are a Choice Registered Merchant or have any relationship
with Choice.`}</p>
      <p>{`11.3.2. You will immediately cease using Choice’s Intellectual Property.`}</p>
      <p>{`11.3.3. You will immediately return to Choice, or destroy at Choice’s direction, any information or property in your
possession pertaining to the Choice Platform.`}</p>
      <h2>{`12. Notices`}</h2>
      <p>{`12.1. Notices: All notices and other communications will be made by us to the email address contained in your Choice Registered Merchant account, and are deemed to be received by you at the time specified in the email transmission provided it was not returned as undeliverable or as containing any error.`}</p>
      <h2>{`13. Definitions and Interpretation`}</h2>
      <p>{`13.1. Definitions: In these terms and conditions, unless the context requires otherwise:`}</p>
      <p>{`13.1.1. Business Day means any day of the week, other than Saturday, Sunday and New Zealand public holidays.`}</p>
      <p>{`13.1.2. Choice means Choice Limited.`}</p>
      <p>{`13.1.3. Choice Platform means the mobile payment processing services used by Customers to purchase and pay for goods and/or services from Choice Registered Merchants.`}</p>
      <p>{`13.1.4. Choice Registered Merchant means a retailer, business or service provider who accepts payments for its goods and/or services through the Choice Platform and is registered as a merchant with Choice.`}</p>
      <p>{`13.1.5. Customer means a person who registers to use the Choice Platform.`}</p>
      <p>{`13.1.6. Merchant means a person who registers as a Choice Registered Merchant`}</p>
      <p>{`13.1.7 Website means`}<a parentName="p" {...{
          "href": "https://www.choicetopay.com"
        }}>{`www.choicetopay.com`}</a></p>
      <p>{`13.1.8. You or Your is a reference to Merchants.`}</p>
    </SectionBox>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      