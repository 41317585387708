import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import PageLayout from '../components/PageLayout';
import MerchantTerms from '../components/merchant-terms/MerchantTerms';
export const metadata = {
  title: 'Merchant Terms'
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <MerchantTerms mdxType="MerchantTerms" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      